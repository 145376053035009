import { getLoggedInUser } from "./authUtils";
 
export function authMiddleware() {
    let realFetch = window.fetch;
    window.fetch = function(url, opts) {
        return new Promise((resolve, reject) => {
            const user = getLoggedInUser()

            if (user && user.access_token !== undefined) {
                opts.headers = {
                    Authorization: `Bearer ${user.access_token}`,
                }    
            }

            // pass through any requests not handled above
            realFetch(url, opts).then(response => resolve(response));
        });
    };
}
