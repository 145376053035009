// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    FETCH_USER_PERMISSIONS,
    FETCH_USER_PERMISSIONS_SUCCESS,
    FETCH_USER_PERMISSIONS_FAILED,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    passwordResetStatus: {},
    permissions:[],
    loading: false,
};


const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, action: action.type, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, action: action.type, user: action.payload, loading: false, error: null };
        case LOGIN_USER_FAILED:
            return { ...state, action: action.type, error: action.payload, loading: false };
        case LOGOUT_USER:
            return { ...state, action: action.type, user: null };
        case FORGET_PASSWORD:
            return { ...state, action: action.type, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, action: action.type, passwordResetStatus: action.payload, loading: false, error: null };
        case FORGET_PASSWORD_FAILED:
            return { ...state, action: action.type, error: action.payload, loading: false };
        case FETCH_USER_PERMISSIONS:
            return { ...state, action: action.type, loading: true };
        case FETCH_USER_PERMISSIONS_SUCCESS:
            return { ...state, action: action.type, permissions: action.payload, loading: false, error: null };
        case FETCH_USER_PERMISSIONS_FAILED:
            return { ...state, action: action.type, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default Auth;
