import { fetchJSON } from './../helpers/api';
import { API_PATH } from './../constants'


export const fetchLifecycles = async (page = 1, page_size = 10, search, visibility='') => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/lifecycles/?page=${page}&page_size=${page_size}&search=${search}&layer__visibility=${visibility}`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const removeLifecycle = async ( id = 1 ) => {
    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/lifecycles/${id}/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const loadLifecycle = async (id) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/lifecycles/${id}/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const fetchReviewTypes = async (lifecycle_id = 0) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/review_types/?lifecycle_id=${lifecycle_id}`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const createLifecycle = async (code, title, usability, downloadable, file, style, public_type) => {
    var formData = new FormData();
    formData.append('code', code);
    formData.append('title', title);
    formData.append('visibility', usability);
    formData.append('downloadable', downloadable)
    if (public_type != 'public_kobo_layer'){
        formData.append('file', file[0]);
        formData.append('style', style[0]);
    }
    formData.append('public_type', public_type);

    const options = {
        body: formData,
        method: 'POST',
    };

    try {
        var response = await fetchJSON(API_PATH + 'api/lifecycles/', options)
        return response
    } catch (error) {
        throw error
    }
}

export const updateLayer = async (action, lifecycle_id, visibility, metadata, status, file, public_type='') => {
    var formData = new FormData();
    formData.append('action', action);
    formData.append('lifecycle_id', lifecycle_id);
    if (action === "update_visibility"){
      formData.append('visibility', visibility);
    }
    if (action === "update_metadata"){
      formData.append('metadata', metadata);
    }
    if (action === "change_status"){
      formData.append('status', status);
    }
    if (action === "publish"){
        if (file){
            formData.append('file', file[0]);
        }
        formData.append('public_type', public_type);
    }

    const options = {
        body: formData,
        method: 'POST',
    };

    try {
        var response = await fetchJSON(API_PATH + 'api/layers/', options)
        return response
    } catch (error) {
        throw error
    }
}

export const addQA = async (lifecycle_id, review_type_id, approved ) => {
    var formData = new FormData();
    formData.append('lifecycle_id', lifecycle_id);
    formData.append('review_type_id', review_type_id);
    formData.append('approved', approved);

    const options = {
        body: formData,
        method: 'POST',
    };

    try {
        var response = await fetchJSON(API_PATH + 'api/quality_assurances/', options)
        return response
    } catch (error) {
        throw error
    }
}

export const fetchLifecycleLogs = async (action_by="", lifecycle="", page = 1, page_size = 10 ) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/lifecycle_logs/?action_by=${action_by}&lifecycle=${lifecycle}&page=${page}&page_size=${page_size}`, options)
        return response
    } catch (error) {
        throw error
    }
}
