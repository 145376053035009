import {getLoggedInUser} from "./authUtils";
import {Cookies} from 'react-cookie';
import jsPDF from 'jspdf'
import {getPointResolution, get as getProjection} from 'ol/proj';
import html2canvas from 'html2canvas';
import logo from '../assets/images/logo.png';
import logos from '../assets/images/logos.png';
import north from '../assets/images/north-icon.png';
import moment from 'moment'
import 'moment/locale/es'
import {GEO_PROJ} from '../constants/api'

export function numberWithCommas(x) {
	let amount = parseFloat(x).toFixed(2)
	return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isValidJsonString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export function checkPermissions(permissions, user_permissions) {
	const loggedInUser = getLoggedInUser()
	var havePermission = false

	if (permissions && user_permissions) {
		user_permissions.map((user_permission) => {
			if (permissions.indexOf(user_permission.codename) > -1) {
				havePermission = true
			}
			return havePermission
		})
	}

	/** Si es superusuario puede ver todos las rutas */
	if (loggedInUser.is_superuser) {
		havePermission = true
	}


	return havePermission
}

/**
 * Sets the session
 * @param {*} user
 */
export function setSession(user) {
	let cookies = new Cookies();
	if (user) cookies.set('user', JSON.stringify(user), {
		path: '/'
	});
	else cookies.remove('user', {
		path: '/'
	});
};


export function generateMapToPDF(title,map, mapContainer) {
	document.body.style.cursor = 'progress';
	const format = 'a4'

	var size = /** @type {module:ol/size~Size} */ (map.getSize())
	var width = size[0]
	var height = size[1]
	var ratio = height / width
	const viewResolution = map.getView().getResolution();
	var extent = map.getView().calculateExtent(size)
	const exportOptions = {
		useCORS: true,
		ignoreElements: function (element) {
			if( 
				element.classList.contains( 'ol-control' ) || 
				element.classList.contains( 'ol-attribution' ) || 
				element.classList.contains( 'layers-box' ) || 
				element.classList.contains( 'base-maps-box' ) || 
				element.classList.contains( 'btn-print' ) ||
				element.classList.contains( 'btn-line-measure' )  || 
				element.classList.contains( 'btn-polygon-measure' ) ||
				element.classList.contains( 'btn-catalogue' )
			) {
				return true;
			}
        }
	};

	map.once('rendercomplete', function () {

		html2canvas(mapContainer, exportOptions).then(function (canvas) {
			const pdf = new jsPDF("l", "pt", format);
			var pageWidth = pdf.internal.pageSize.getWidth();
			var pageHeight = pdf.internal.pageSize.getHeight();
			let date = new Date()

			pdf.addImage(
				canvas.toDataURL('image/jpeg'),
				'JPEG',
				0,
				60,
				pageWidth,
				ratio * pageWidth
			);
			
			//Draw PDF header
			var titlePDF = title.toUpperCase()
			pdf.text(20,36, titlePDF)

			var mainLogo = new Image()
			mainLogo.src = logo
			pdf.addImage(mainLogo, 'PNG', pageWidth - 227, 0, 227, 54);

			//Draw PDF Metadata
			pdf.setFillColor(233, 255, 190);
			pdf.rect(0, pageHeight - 120, pageWidth, 90, 'F');

			pdf.setFontSize(12);
			pdf.setTextColor(0, 0, 0)

			pdf.text(15, pageHeight - 100, `Sistema de Coordenadas: World Geodetic System 1984`, )

			pdf.text(15, pageHeight - 80, `Proyección: ${GEO_PROJ}`, )
			
			let currentDate = moment(date).format('LLLL');
			pdf.text(15, pageHeight - 60, `Fecha: ${currentDate}`, )

			pdf.text(15, pageHeight - 40, `Fuente: Servicio de Información Agroalimentaria`, )

			var northIcon = new Image()
			northIcon.src = north
			pdf.addImage(northIcon, 'PNG', pageWidth * 0.90, pageHeight - 100 , 41, 60);
			
			//Draw PDF footer
			pdf.setFillColor(252, 252, 252);
			pdf.rect(0, pageHeight - 30, pageWidth, 30, 'F');

			let currentYear = date.getFullYear()
			var footerPDF = `${currentYear} © InfoAgro - SAG. Todos los derechos reservados.`
			pdf.setFontSize(10);
			pdf.setTextColor(6, 6, 6)
			pdf.text(130,pageHeight - 10, footerPDF, )

			var otherLogos = new Image()
			otherLogos.src = logos
			pdf.addImage(otherLogos, 'PNG', 10, pageHeight - 24, 117, 24);

			pdf.save(`${title}.pdf`);
			// Reset original map size
			map.updateSize();
			map.getView().setResolution(viewResolution);
			document.body.style.cursor = 'auto';
		});

	})

	var printSize = [width, height]
	map.setSize(printSize)
	map.getView().fit(extent, {
		size: printSize
	})
};

export function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
}