import { fetchJSON } from '../helpers/api';
import { API_PATH } from '../constants'


export const fetchDepartments = async () => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/departments/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const fetchMunicipalities = async (depto_code) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/municipalities/?depto_code=${depto_code}&page_size=100`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const addUserByDepartment = async (user, department) => {
    var formData = new FormData();
    formData.append('user', user);
    formData.append('department', department);
    formData.append('active', true);

    const options = {
        body: formData,
        method: 'POST',
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/users_by_departments/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const addUserByMunicipality = async (user, municipality) => {
    var formData = new FormData();
    formData.append('user', user);
    formData.append('municipality', municipality);
    formData.append('active', true);

    const options = {
        body: formData,
        method: 'POST',
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/users_by_municipalities/`, options)
        return response
    } catch (error) {
        throw error
    }
}
