import { fetchJSON } from '../helpers/api';
import { API_PATH } from '../constants'


export const fetchTutorials = async (visibility='') => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/tutorials/?visibility=${visibility}`, options)
        return response
    } catch (error) {
        throw error
    }
}