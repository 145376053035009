import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated } from '../helpers/authUtils';
import Collection from 'ol/Collection';
import TutorialList from '../pages/tutorial';


// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const PasswordResetConfirm = React.lazy(() => import('../pages/auth/PasswordResetConfirm'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Loader = React.lazy(() => import('../pages/auth/Loader'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/home'));
// apps
const Settings = React.lazy(() => import('../pages/settings'));
const Profile = React.lazy(() => import('../pages/profile'));

// const DepartmentList = React.lazy(() => import('../pages/locations/Department/List'));
// const DepartmentAdd = React.lazy(() => import('../pages/locations/Department/Add'));
// const DepartmentEdit = React.lazy(() => import('../pages/locations/Department/Edit'));

// const MunicipalityList = React.lazy(() => import('../pages/locations/Municipality/List'));
// const MunicipalityAdd = React.lazy(() => import('../pages/locations/Municipality/Add'));
// const MunicipalityEdit = React.lazy(() => import('../pages/locations/Municipality/Edit'));

// const VillagesList = React.lazy(() => import('../pages/locations/Villages/List'));
// const VillagesAdd = React.lazy(() => import('../pages/locations/Villages/Add'));
// const VillagesEdit = React.lazy(() => import('../pages/locations/Villages/Edit'));

//Accounts

const UsersList = React.lazy(() => import('../pages/accounts/Users/List'));
const UsersAdd = React.lazy(() => import('../pages/accounts/Users/Add'));
const UsersEdit = React.lazy(() => import('../pages/accounts/Users/Edit'));

const RolesList = React.lazy(() => import('../pages/accounts/Roles/List'));
const RolesAdd = React.lazy(() => import('../pages/accounts/Roles/Add'));
const RolesEdit = React.lazy(() => import('../pages/accounts/Roles/Edit'));


const LifecycleList = React.lazy(() => import('../pages/lifecycle/List'));
const LifecyclePublish = React.lazy(() => import('../pages/lifecycle/Lifecycle'));
const LifecyclePublicated = React.lazy(() => import('../pages/lifecycle/LifecyclePublicated'));

const CollectionList = React.lazy(() => import('../pages/collection/List'));
const CollectionDetail = React.lazy(() => import('../pages/collection/Detail'));

const PrivateGeoportal = React.lazy(() => import('../pages/geoportal/private'));
const PublicGeoportal = React.lazy(() => import('../pages/geoportal/public'));

const LayerGroup = React.lazy(() => import('../pages/layerGroup'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));


// handle auth and authorization
const PrivateRoute = ({ component: Component, permissions, user_permissions, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            
        
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/geoportal_public', state: { from: props.location } }} />;
            }


            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
    route: PrivateRoute,
};

// home
const homeRoutes = {
    path: '/home',
    name: 'Inicio',
    icon: FeatherIcon.Home,
    header: 'Menu del Sistema',
    component: Dashboard,
    permissions: ['view_home'],
    route: PrivateRoute
};

const settingRoutes = {
    path: '/settings',
    name: 'Ajustes',
    icon: FeatherIcon.Settings,
    component: Settings,
    permissions: ['view_settings'],
    route: PrivateRoute,
    hidden: true,
};

const lifecycleListRoute = {
    path: '/lifecycle/list',
    name: 'Ciclo de vida',
    icon: FeatherIcon.CheckSquare,
    component: LifecycleList,
    permissions: ['view_lifecycle'],
    route: PrivateRoute
};

const lifecyclePublishRoute = {
    pathParent: '/lifecycle',
    path: '/lifecycle/detail/:id/:public_type?/:kobo_layer_id?',
    component: LifecyclePublish,
    permissions: ['add_lifecycle'],
    route: PrivateRoute,
    hidden: true,
};

const lifecyclePublicatedRoute = {
    pathParent: '/lifecycle',
    path: '/lifecycle/publicated/:id',
    component: LifecyclePublicated,
    permissions: ['view_lifecycle'],
    route: PrivateRoute,
    hidden: true,
};

const collectionListRoute = {
    path: '/collection/list',
    name: 'Recolección',
    icon: FeatherIcon.List,
    component: CollectionList,
    permissions: ['view_collection'],
    route: PrivateRoute
};


const collectionDetailRoute = {
    pathParent: '/collection',
    path: '/collection/detail/:id/:id_string',
    name: 'Geoportal',
    component: CollectionDetail,
    permissions: ['detail_collection'],
    route: PrivateRoute,
    hidden: true,
};

const privateGeoportalRoute = {
    path: '/geoportal_private',
    name: 'Geoportal',
    icon: FeatherIcon.Layers,
    component: PrivateGeoportal,
    permissions: ['view_geoportal'],
    route: PrivateRoute
};

const layerGroupRoute = {
    path: '/layer_groups',
    name: 'Grupo de Capas',
    icon: FeatherIcon.FolderPlus,
    component: LayerGroup,
    permissions: ['add_layergroup'],
    route: PrivateRoute
};

const userProfileRoute = {
    path: '/profile',
    component: Profile,
    permissions: ['view_profile'],
    route: PrivateRoute,
    hidden: true,
};

const publicGeoportalRoute = {
    path: '/geoportal_public',
    name: 'Geoportal',
    component: PublicGeoportal,
    route: Route
};

const page404Route = {
    path: '*',
    name: '404',
    component: Error404,
    route: Route
};

// const locationsAppRoutes = {
//     path: '/locations',
//     name: 'Ubicaciones',
//     permissions: ['view_village','view_departament','view_municipality'],
//     icon: FeatherIcon.Map,
//     children: [
//         {
//             path: '/locations/departamentos/list',
//             pathParent: '/locations/departamentos',
//             name: 'Departamentos',
//             component: DepartmentList,
//             route: PrivateRoute,
//             permissions: ['view_departament'],
//         },
//         {
//             path: '/locations/departamentos/add',
//             pathParent: '/locations/departamentos',
//             component: DepartmentAdd,
//             route: PrivateRoute,
//             permissions: ['add_departament'],
//             hidden: true,
//         },
//         {
//             path: '/locations/departamentos/edit/:id',
//             pathParent: '/locations/departamentos',
//             component: DepartmentEdit,
//             route: PrivateRoute,
//             permissions: ['change_departament'],
//             hidden: true,
//         },

//         {
//             path: '/locations/municipios/list',
//             pathParent: '/locations/municipios',
//             name: 'Municipios',
//             component: MunicipalityList,
//             route: PrivateRoute,
//             permissions: ['view_municipality'],
//         },
//         {
//             path: '/locations/municipios/add',
//             pathParent: '/locations/municipios',
//             component: MunicipalityAdd,
//             route: PrivateRoute,
//             permissions: ['add_municipality'],
//             hidden: true,
//         },
//         {
//             path: '/locations/municipios/edit/:id',
//             pathParent: '/locations/municipios',
//             component: MunicipalityEdit,
//             route: PrivateRoute,
//             permissions: ['change_municipality'],
//             hidden: true,
//         },

//         {
//             path: '/locations/aldeas/list',
//             pathParent: '/locations/aldeas',
//             name: 'Aldeas',
//             component: VillagesList,
//             route: PrivateRoute,
//             permissions: ['view_village'],
//         },
//         {
//             path: '/locations/aldeas/add',
//             pathParent: '/locations/aldeas',
//             component: VillagesAdd,
//             route: PrivateRoute,
//             permissions: ['add_village'],
//             hidden: true,
//         },
//         {
//             path: '/locations/aldeas/edit/:id',
//             pathParent: '/locations/aldeas',
//             component: VillagesEdit,
//             route: PrivateRoute,
//             permissions: ['change_village'],
//             hidden: true,
//         },
//     ]
// };

const userListRoute = {
    path: '/users/list',
    name: 'Usuarios',
    icon: FeatherIcon.Users,
    component: UsersList,
    permissions: ['view_user'],
    route: PrivateRoute
};

const userAddRoute = {
    pathParent: '/users',
    path: '/users/add',
    component: UsersAdd,
    permissions: ['add_user'],
    route: PrivateRoute,
    hidden: true,
};

const userEditRoute = {
    pathParent: '/users',
    path: '/users/edit/:id/',
    component: UsersEdit,
    permissions: ['change_user'],
    route: PrivateRoute,
    hidden: true,
};

const tutorialsRoute = {
    path: '/tutorials',
    name: 'Tutoriales y Manuales',
    component: TutorialList,
    permissions: ['view_tutorial'],
    route: PrivateRoute,
    hidden: true,
};

const appRoutes = [
    lifecycleListRoute,
    lifecyclePublishRoute , 
    lifecyclePublicatedRoute, 
    collectionListRoute,
    privateGeoportalRoute, 
    collectionDetailRoute,
    layerGroupRoute,
    userListRoute,
    userAddRoute,
    userEditRoute,
    tutorialsRoute,
    userProfileRoute
];

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/loader',
            name: 'Login',
            component: Loader,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/password-reset-confirm/:uid/:token',
            name: 'Reset Password',
            component: PasswordResetConfirm,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    homeRoutes,
    settingRoutes,
    ...appRoutes,
    authRoutes,
    publicGeoportalRoute,
    page404Route
];

const authProtectedRoutes = [homeRoutes, ...appRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
