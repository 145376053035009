import React, {useState, useEffect} from 'react';
import { Row, Col,  Card, CardBody, } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getLoggedInUser } from '../../helpers/authUtils';
import { GEO_PATH } from '../../constants/api';
import Loader from '../../components/Loader';
import * as FeatherIcon from 'react-feather';

import StatisticsWidget from '../../components/StatisticsWidget';

import * as Services from '../../services';

import { checkPermissions } from '../../helpers/utility';
import classNames from 'classnames';


function TutorialList(props) {
    const [loading, setLoading] = useState(true);
    const [tutorials, setTutorials] = useState([]);

    useEffect(() => {
        fetchTutorials()
    },[]);

    const fetchTutorials = async () => {
        var request = Services.fetchTutorials()
        request.then(response => {
            setLoading(false)
            setTutorials(response)
        }).catch(error => {
 
        })
    }


    return (
        <React.Fragment>
            <div className="">
                { /* preloader */}
                {loading && <Loader />}

                <Row className="page-title align-items-center">
                    <Col sm={4} xl={6}>
                        <h4 className="mb-1 mt-0">Tutoriales y Manuales</h4>
                    </Col>
                </Row>
                <Row>
                    {tutorials.map((tutorial)=>{
                        return (
                            <Col xl={3} md={6} sm={12} key={tutorial.id}>
                                <Card className="mb-4 mb-xl-0">
                                    <div className={classNames( 'd-flex', 'p-3', 'justify-content-center', { 'bg-primary': tutorial.type_tutorial === 'pdf', 'bg-warning': tutorial.type_tutorial === 'video' })} >
                                        {tutorial.type_tutorial == 'pdf'? (
                                            <FeatherIcon.File color={'white'} size={'30%'}></FeatherIcon.File>
                                        ):(
                                            <FeatherIcon.Video color={'white'} size={'30%'}></FeatherIcon.Video>
                                        )}
                                    </div>
                                    <CardBody>
                                        <h5 className="card-title font-size-16">{tutorial.title}</h5>
                                    </CardBody>
                                    <CardBody>
                                        {tutorial.tutorial_file && (
                                            <a href={tutorial.tutorial_file} target="_blank" className="card-link text-custom">Abrir Archivo</a>
                                        )}
                                        {tutorial.tutorial_url && (
                                            <a href={tutorial.tutorial_url} target="_blank" className="card-link text-custom">Ver Video</a>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </React.Fragment>
    )
}


export default TutorialList;