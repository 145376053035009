import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { ArrowLeftCircle } from 'react-feather';

const contextClass = {
  success: "alert-success",
  error: "alert-danger",
  info: "alert-info",
  warning: "alert-warning",
  default: "alert-primary",
  dark: "alert-secondary",
};

const CloseButton = ({ closeToast }) => (
  <button 
    aria-label="Close"
    className="close"
    onClick={closeToast}
  >
    <span aria-hidden="true">×</span>
  </button>
);

class Toast extends Component {

    render() {
        return (
          <ToastContainer
            toastClassName={({ type }) => contextClass[type || "default"] + 
              " alert alert-dismissible flex p-1 min-h-10 justify-between overflow-hidden cursor-pointer"
            }
            bodyClassName={() => "text-sm font-white font-med block p-3"}
            position="top-right"
            closeButton={CloseButton} 
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            />
        )
    }
}

export default Toast;