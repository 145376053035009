import { fetchJSON } from '../helpers/api';
import { API_PATH } from '../constants'

export const fetchUsers = async ( page = 1, page_size = 10, search ) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/users/?page=${page}&page_size=${page_size}&search=${search}`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const loadUser = async ( id = 1 ) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/users/${id}/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const removeUser = async ( id = 1 ) => {
    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/users/${id}/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const editUser = async ( id, username, first_name, last_name, email, is_superuser = false, roles, municipalities, departments) => {
    var formData = new FormData();
    formData.append('username', username);
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('email', email);
    formData.append('is_superuser', is_superuser);
    formData.append('rol', roles);
    if (municipalities.length > 0){
        formData.append('municipalities', municipalities);
    }
    if (departments.length > 0){
        formData.append('departments', departments);
    }

    const options = {
        body: formData,
        method: 'PUT',
    };


    try {
        var response = await fetchJSON(API_PATH + `api/users/${id}/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const resetPasswordUser = async ( id, password) => {
    var formData = new FormData();
    formData.append('pk', id);
    formData.append('password', password);

    const options = {
        body: formData,
        method: 'POST',
    };

    try {
        var response = await fetchJSON(API_PATH + 'api/reset_password/', options)
        return response
    } catch (error) {
        throw error
    }
}

export const changePasswordUser = async ( new_password, old_password) => {
    var formData = new FormData();
    formData.append('new_password', new_password);
    formData.append('old_password', old_password);

    const options = {
        body: formData,
        method: 'POST',
    };

    try {
        var response = await fetchJSON(API_PATH + 'api/change_password/', options)
        return response
    } catch (error) {
        throw error
    }
}

export const resetPasswordConfirm = async ( password, confirm_password, uid, token) => {
    var formData = new FormData();
    formData.append('new_password1', password);
    formData.append('new_password2', confirm_password);
    formData.append('uid', uid);
    formData.append('token', token);

    const options = {
        body: formData,
        method: 'POST',
    };

    try {
        var response = await fetchJSON(API_PATH + `password-reset-confirm/${uid}/${token}/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const addUser = async ( username, password, first_name, last_name, email, is_superuser = false, roles=[]) => {
    var formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('email', email);
    formData.append('is_active', true);
    formData.append('is_staff', false);
    if (roles.length > 0){
        formData.append('groups', roles);
    }
    formData.append('is_superuser', is_superuser);

    const options = {
        body: formData,
        method: 'POST',
    };

    try {
        var response = await fetchJSON(API_PATH + 'api/users/', options)
        return response
    } catch (error) {
        throw error
    }
}

export const fetchRoles = async ( page = 1, page_size = 10, search = '' ) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/groups/?page=${page}&page_size=${page_size}&search=${search}`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const removeRol = async ( id = 1 ) => {
    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/roles/${id}/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const loadRol = async ( id ) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/groups/${id}/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const addRol = async ( name, permissions ) => {
    var formData = new FormData();
    formData.append('name', name);
    permissions.map((permission)=>{
        return formData.append('permissions', permission);
    })

    const options = {
        body: formData,
        method: 'POST',
    };

    try {
        var response = await fetchJSON(API_PATH + 'api/roles/', options)
        return response
    } catch (error) {
        throw error
    }
}

export const editRol = async ( id, name, permissions  ) => {
    var formData = new FormData();
    formData.append('name', name);
    permissions.map((permission)=>{
        return formData.append('permissions', permission);
    })
    formData.append('active', true);

    const options = {
        body: formData,
        method: 'PUT',
        headers: { 
            'Accept': 'application/json',
        },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/roles/${id}/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const fetchPermissions = async ( page = 1, page_size = 10 ) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/permissions/?page=${page}&page_size=${page_size}`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const addLayersByUser = async ( user_id ) => {
    var formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('action', 'add');
    
    const options = {
        body: formData,
        method: 'POST',
        headers: { 
            'Accept': 'application/json',
        },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/layers_by_user/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const editLayersByUser = async ( user_id, layers ) => {
    var formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('layers', layers);
    formData.append('action', 'edit');
    
    const options = {
        body: formData,
        method: 'POST',
        headers: { 
            'Accept': 'application/json',
        },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/layers_by_user/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const fetchLayersByUser = async ( user_id ) => {
    const options = {
        method: 'GET',
        headers: { 
            'Accept': 'application/json',
        },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/layers_by_user/?user=${user_id}`, options)
        return response
    } catch (error) {
        throw error
    }
}

