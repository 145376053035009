// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { LOGIN_USER, LOGOUT_USER, FORGET_PASSWORD, FETCH_USER_PERMISSIONS } from './constants';

import {
    loginUserSuccess,
    loginUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
    fetchUserPermissionsSuccess,
    fetchUserPermissionsFailed,
} from './actions';

import {
    API_PATH
} from '../../constants'
import { isValidJsonString, setSession } from '../../helpers/utility';

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, password } }) {
    const options = {
        body: JSON.stringify({ email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, API_PATH + 'login/', options);
        setSession(response)
        yield put(loginUserSuccess(response));
    } catch (error) {
        let message = '';
        
        if (isValidJsonString(error.message)){
            message = JSON.parse(error.message)
        }

        yield put(loginUserFailed(message));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null)
        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email } }) {
    const options = {
        body: JSON.stringify({ email }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, API_PATH + 'password-reset/', options);
        yield put(forgetPasswordSuccess(response));
    } catch (error) {
        let message = '';
        
        if (isValidJsonString(error.message)){
            message = JSON.parse(error.message)
        }

        yield put(forgetPasswordFailed(message));
    }
}

function* fetchUserPermissions({ payload: { id } }) {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
  
    try {
        const response = yield call(fetchJSON, API_PATH + `api/groups/${id}/`, options);
        yield put(fetchUserPermissionsSuccess(response.permissions_list));
    } catch (error) {
        let message;
        yield put(fetchUserPermissionsFailed(message));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchFetchUserPermissions() {
    yield takeEvery(FETCH_USER_PERMISSIONS, fetchUserPermissions);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchForgetPassword), fork(watchFetchUserPermissions)]);
}

export default authSaga;
