require('dotenv').config()

export const API_PATH = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/' : 'http://geoportal.infoagro.hn/'
export const GEO_PATH = process.env.NODE_ENV === 'development' ? 'http://geoservicios.infoagro.hn/' : 'http://geoservicios.infoagro.hn/'
export const GEO_PROJ = 'EPSG:4326'

export const PAGE_SIZE = 10

export const WORKSPACE_PUBLIC = 'agromapas_public'
export const WORKSPACE_PRIVATE = 'agromapas_private'

export const KOBO_USERNAME = process.env.NODE_ENV === 'development' ? 'nelkit' : 'infoagrohn'
export const KOBO_MEDIA_ID = process.env.NODE_ENV === 'development' ? 'a1df0f21956f4e56b456c79ce63bc7ce' : 'd86b4ad83bcf495ca3636ad7c284e195'