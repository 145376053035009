import { fetchJSON } from '../helpers/api';
import { API_PATH } from '../constants'


export const fetchLayerGroups = async (visibility='') => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/layer_groups/?visibility=${visibility}`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const fetchLayers = async (search='') => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/layers/?search=${search}`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const fetchPublicLayerGroups = async () => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/public_layer_groups/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const fetchPublicLayers = async (search='') => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/public_layers/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const getLayerInfo = async (url) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',},
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/layer_properties/?url=${url}`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const fetchKoboLayers = async () => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
 
    try {
        var response = await fetchJSON(`${API_PATH}api/kobo_layers/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const getKoboSyncData = async (id) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
 
    try {
        var response = await fetchJSON(`${API_PATH}api/kobo_sync_data/?id=${id}`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const getKoboLayer = async (id) => {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/kobo_layers/${id}/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const convertShpToJson = async (shapefile) => {
    var formData = new FormData();
    formData.append('file', shapefile);

    const options = {
        method: 'POST',
        body: formData
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/shp_to_geojson/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const convertKMLToJson = async (kml) => {
    var formData = new FormData();
    formData.append('file', kml);

    const options = {
        method: 'POST',
        body: formData
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/kml_to_geojson/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const wmsGetCapabilities = async (service_url) => {
    const options = {
        method: 'GET'
    };

    try {
        var response = await fetchJSON(`${API_PATH}api/wms_capabilities/?service_url=${service_url}`, options)
        return response
    } catch (error) {
        throw error
    }
}


