import { fetchJSON } from '../helpers/api';
import { API_PATH } from '../constants'

export const addLayerGroup = async ( title, parent, group_type, layer, priority, visibility) => {
    var formData = new FormData();
    formData.append('title', title);
    formData.append('group_type', group_type);
    formData.append('priority', priority);
    formData.append('visibility', visibility);
    if (parent != '-1'){
        formData.append('parent', parent);
    }
    if (layer){
        formData.append('layer', layer);
    }

    const options = {
        body: formData,
        method: 'POST',
    };

    try {
        var response = await fetchJSON(API_PATH + 'api/layer_groups/', options)
        return response
    } catch (error) {
        throw error
    }
}

export const editLayerGroup = async ( id, title, priority) => {
    var formData = new FormData();
    formData.append('title', title);
    formData.append('priority', priority);

    const options = {
        body: formData,
        method: 'PUT',
    };

    try {
        var response = await fetchJSON(API_PATH + `api/layer_groups/${id}/`, options)
        return response
    } catch (error) {
        throw error
    }
}

export const removeLayerGroup = async ( id ) => {
    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        var response = await fetchJSON(API_PATH + `api/layer_groups/${id}/`, options)
        return response
    } catch (error) {
        throw error
    }
}